// 严凯 V2.2.0
<template>
  <div class="page-add">
    <staffForm
      types="edit"
      :current="current"
      @submit="submit"
      :organizationalList="organizationalList"
      :roleList="roleList"
      v-model:loading="loading"
    ></staffForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, onMounted, computed } from 'vue'
import { cmsNotice } from '@/utils/utils'
import '@/style/page-add.scss'
import { useRouter } from 'vue-router'
import staffForm from './components/form'
import { useStore } from 'vuex'
import { SET_USER_INFO } from '@/store/user/mutations-type'
import { addUser, editUser, getDepartmentList, getRoleClassify } from '@/apis/businessManage'
const isLeaderOptions = [
  {
    id: 0,
    name: '否'
  },
  {
    id: 1,
    name: '是'
  }
]
export default defineComponent({
  components: {
    staffForm
  },
  props: {},
  setup() {
    const store = useStore()
    const formRef = ref()
    const router = useRouter()
    const isBindLbw = computed(() => store.state.user.userInfo.enterpriseBindLibawall)
    const modalVal = reactive({
      staffName: undefined,
      account: undefined,
      role: undefined,
      department: undefined,
      isLeader: 0
    })
    const current = JSON.parse(localStorage.getItem('staffCurrent'))
    const state = reactive({
      lbwList: [],
      isVirtualListScroll: 0,
      loading: false,
      roleList: [],
      organizationalList: [],
      isShowSreach: false
    })
    const submit = async value => {
      if (value.account && !/^1(3|4|5|6|7|8|9)\d{9}$/.test(value.account)) {
        cmsNotice('error', '手机号格式错误')
        state.loading = false
        return
      }
      const params = {
        id: current.staffId,
        roleId: value.role ? [value.role] : null,
        name: value.staffName.trim(),
        mobile: value.account,
        deptId: value.department,
        attachAbility: value.attachAbility,
        isLeader: value.isLeader
      }
      const localData = JSON.parse(JSON.stringify(params))
      localData.role = value.role
      localData.department = value.department
      const res = await editUser(params)
      if (res.success) {
        cmsNotice('success', '修改成功')
        console.log('params', JSON.stringify(localData))
        localStorage.setItem('staffCurrent', JSON.stringify(localData))
        // 更新右上角名称
        const userInfo = JSON.parse(localStorage.getItem('yda-admin-userInfo'))
        if (current.userId === userInfo.userId) {
          userInfo.userName = params.name
          localStorage.setItem('yda-admin-userInfo', JSON.stringify(userInfo))
          store.commit(`user/${SET_USER_INFO}`, userInfo)
        }
        router.back()
      }
      state.loading = false
      // state.visible = false
    }
    const getRoleList = async () => {
      // 获取角色列表
      const res = await getRoleClassify()
      // state.roleList = res.data.map()
      if (res.success) {
        state.roleList = res.data.map(item => {
          item.label = item.name
          item.value = item.id
          return item
        })
      }
    }
    const getOrganizationalList = async () => {
      const res = await getDepartmentList()
      state.organizationalList = res.data
    }
    const comfirmAdd = () => {
      state.loading = true
      formRef.value
        .validate()
        .then(() => {
          staffAdd()
        })
        .catch(() => (state.loading = false))
    }
    const staffAdd = async () => {
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(modalVal.account)) {
        cmsNotice('error', '手机号格式错误')
        state.loading = false
        return
      }
      const params = {
        roleId: [modalVal.role],
        name: modalVal.staffName.trim(),
        mobile: modalVal.account,
        deptId: modalVal.department[modalVal.department.length - 1],
        isLeader: modalVal.isLeader
      }
      const res = await addUser(params)
      if (res.success) {
        cmsNotice('success', '添加成功')
        router.back()
      }
      state.loading = false
    }
    const handleCancel = () => {
      // 关闭弹框
      formRef.value.resetFields()
      router.back()
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) >= 0
    }
    onMounted(() => {
      getRoleList()
      getOrganizationalList()
    })

    return {
      ...toRefs(state),
      comfirmAdd,
      formRef,
      modalVal,
      handleCancel,
      staffAdd,
      filterOption,
      isLeaderOptions,
      current,
      submit,
      isBindLbw
    }
  }
})
</script>

<style lang="scss" scoped>
:deep(.ant-form-item-label) {
  width: 82px;
}
.form-item-cascader {
  :deep(.ant-cascader-menu:nth-child(1)) {
    width: 468px;
  }
}
</style>
